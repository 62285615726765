import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import { DIMSUM } from "../styles/colors"
import { TYPOGRAPHY } from "../styles/typography"

import LogoBlackOnYellow from "../assets/logo_color.svg"


const StyledLogo = styled(LogoBlackOnYellow)`
  height: 28px;
  width: 30px;

  & g#over path {
    fill: ${props => props.over};
  }

  & g#under path {
    fill: ${props => props.under};
  }

  @media only screen and (min-width: 450px) {
    height: ${props => props.responsive ? "48px" : "28px"};
    width: ${props => props.responsive ? "50px" : "30px"};
  }
`;

const Title = styled.h1`
  color: ${DIMSUM.BLACK};
  font-family: ${TYPOGRAPHY.LUST.FONT_FAMILY};
  font-style: ${TYPOGRAPHY.ITALIC};
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 0 8px;

  @supports (-webkit-touch-callout: none){
      letter-spacing: -0.05em;
  }

  @media only screen and (min-width: 450px) {
    font-size: ${props => props.responsive ? "30px" : "22px"};
    line-height: ${props => props.responsive ? "32px" : "24px"};
  }
`
const LogoSimboloWrapper = styled.section`
  display: flex;
  align-items: center;
`;

const LogoSimbolo = ({ over, under, responsive}) => {
  
  return(
      <StaticQuery
        query={graphql`
          query SiteName {
            contentfulPortada {
              nombre
            }
          }
        `}
        render={data => (
            <LogoSimboloWrapper>
                <StyledLogo 
                  over={over}
                  under={under}
                  responsive={responsive}
                />
                <Title
                  responsive={responsive}
                >
                    {data.contentfulPortada.nombre}
                </Title>  
            </LogoSimboloWrapper>
          )}
        />
    );}

export default LogoSimbolo;


LogoSimbolo.defaultProps = {
  over: `WHITE`,
  under: `BLACK`,
  responsive: true,
}