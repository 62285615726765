const TYPOGRAPHY = {
    LUST: {
        FONT_FAMILY: "lust-display, serif",
        FONT_STYLE: {
            NORMAL: "normal",
        },
    },
    ITALIC: "italic",
    NEUE: {
        FONT_FAMILY: "neue-haas-grotesk-display, sans-serif",
        FONT_STYLE: {
            NORMAL: "normal",
        },
        FONT_WEIGHT: {
            NORMAL: "500",
            BOLD: "700"
        },
    },
}

export { TYPOGRAPHY };