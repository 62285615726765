import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import { DIMSUM } from "../styles/colors"
import { TYPOGRAPHY } from "../styles/typography"

import LogoSimbolo from "../components/logosimbolo"

const StyledFooter = styled.footer`
    font-family: ${TYPOGRAPHY.NEUE.FONT_FAMILY};
    font-weight: ${TYPOGRAPHY.NEUE.FONT_WEIGHT.NORMAL};
    font-size: 14px;
    color: ${DIMSUM.BLACK};

    background-color: ${DIMSUM.WHITE};
    padding: 12px 24px;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 60px 36px;
`;


const Tagline = styled.section`
    text-align: right;
    align-self: center;
`;

const Email = styled.section`
    align-self: center;
    text-align: left;
`;

const Social = styled.section`
    text-align: right;
    align-self: center;
`;

const Footer = () => {
    return(
        <StaticQuery
            query={graphql`
                query SiteInfo {
                    contentfulPortada {
                        tagline
                    }
                }
            `}
            render={data => (
                <StyledFooter>
                    <LogoSimbolo
                        over={DIMSUM.WHITE}
                        under={DIMSUM.BLACK}
                        responsive={false}
                     />
                    <Tagline>{data.contentfulPortada.tagline}</Tagline>
                    <Email>hola@dimsum.es</Email>
                    <Social>Twitter | Instagram</Social>
                </StyledFooter>
            )}
            />
    );
}

export default Footer