import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { DIMSUM } from "../styles/colors"
import styled from "styled-components"
import LogoSimbolo from "../components/logosimbolo"

const StyledHeader = styled.header `
  padding: 24px;
`;

const StyledLink = styled(Link)`
  color: ${DIMSUM.BLACK};
  text-decoration: none;
`;

const Header = ({className}) => (
  <StyledHeader
    className={className}
  >
    <StyledLink
      to="/"
    >
      <LogoSimbolo 
        over={DIMSUM.WHITE}
        under={DIMSUM.BLACK}
      />   
    </StyledLink>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
